import React from 'react'
import{BsLinkedin} from 'react-icons/bs'
import{BsFacebook} from 'react-icons/bs'
import{BsTwitter} from 'react-icons/bs'
import{BsInstagram} from 'react-icons/bs'
import{BsYoutube} from 'react-icons/bs'
import{BsBehance} from 'react-icons/bs'

function HeaderSocials() {
  return (
    <div className="header_socials">
        <a href='https://www.linkedin.com/in/jamiulalamrejon/' target="_blank"><BsLinkedin /></a>
        <a href='https://web.facebook.com/jamiulalamrejoncom/' target="_blank"><BsFacebook /></a>
        <a href='https://www.instagram.com/jamiulalamrejon/' target="_blank"><BsInstagram /></a>
        <a href='https://twitter.com/Jamiulalamrejon' target="_blank"><BsTwitter /></a>
        <a href='https://www.youtube.com/channel/UC7dK-BbtXNUYZC-nZ__d1UQ' target="_blank"><BsYoutube /></a>
        <a href='https://www.behance.net/jamiulalamrejon' target="_blank"><BsBehance /></a>
    </div>
  )
}

export default HeaderSocials

import React from 'react'
import './404.css'
import Error from '../assets/404.png'
import { Link } from "react-router-dom";

export default function Page404() {
  return (
    <div>
      <Link to="*">
        <div className="img_center">
      <img className="error_image" src={Error} alt='404 error page'/>
    </div></Link>
    </div>
    
  )
}

import React from 'react'
import './experience.css'
import {AiFillSafetyCertificate} from 'react-icons/ai'
import {TbCertificate} from 'react-icons/tb'
import {IoMdDoneAll} from 'react-icons/io'

function Experience() {
  return (
    <section id='experience'>
      <h5>What Skill I Have</h5>
      <h2>My Experience</h2>

      <div className="container experience__container">
        <div className="experience__marketing">
          <h3>Digital Marketing</h3>
          <div className="experience__content">
            <article className='experience__details'>
            <TbCertificate className='experience__details-icon'/>
              <div>
              <h4>SEO</h4>
              <small className='text-light'>Certified</small>
              </div>
            </article>
            <article className='experience__details'>
            <AiFillSafetyCertificate className='experience__details-icon'/>
              <div>
              <h4>Local SEO</h4>
              <small className='text-light'>Experienced</small>
              </div>
            </article>
            <article className='experience__details'>
            <TbCertificate className='experience__details-icon'/>
              <div>
              <h4>Digital Marketing</h4>
              <small className='text-light'>Certified</small>
              </div>
            </article>
            <article className='experience__details'>
            <AiFillSafetyCertificate className='experience__details-icon'/>
              <div>
              <h4>PPC Campaign</h4>
              <small className='text-light'>Experienced</small>
              </div>
            </article>
            <article className='experience__details'>
            <AiFillSafetyCertificate className='experience__details-icon'/>
              <div>
              <h4>Consulting</h4>
              <small className='text-light'>Experienced</small>
              </div>
            </article>
            <article className='experience__details'>
            <AiFillSafetyCertificate className='experience__details-icon'/>
              <div>
              <h4>Analysis</h4>
              <small className='text-light'>Experienced</small>
              </div>
            </article>
           
          </div>
        </div>

        <div className="experienc__programming">
          <h3>Others Knowledge</h3>
          <div className="experience__content">
            <article className='experience__details'>
            <TbCertificate className='experience__details-icon'/>
              <div>
              <h4>Graphic Design</h4>
              <small className='text-light'>Certified</small>
              </div>
            </article>
            <article className='experience__details'>
            <IoMdDoneAll className='experience__details-icon'/>
              <div>
              <h4>Wordpress</h4>
              <small className='text-light'>Average</small>
              </div>
            </article>
            <article className='experience__details'>
            <TbCertificate className='experience__details-icon'/>
              <div>
              <h4>Web Design</h4>
              <small className='text-light'>Certified</small>
              </div>
            </article>
            <article className='experience__details'>
            <IoMdDoneAll className='experience__details-icon'/>
              <div>
              <h4>Programming</h4>
              <small className='text-light'>Average</small>
              </div>
            </article>
            <article className='experience__details'>
            <AiFillSafetyCertificate className='experience__details-icon'/>
              <div>
              <h4>Flutter</h4>
              <small className='text-light'>7+ Apps on Playsotre</small>
              </div>
            </article>
            <article className='experience__details'>
            <IoMdDoneAll className='experience__details-icon'/>
              <div>
              <h4>Communication</h4>
              <small className='text-light'>Average</small>
              </div>
            </article>
           
          </div>
        </div>
      </div>
    </section>
  )
}

export default Experience


import React from 'react'
import './header.css'
import CTA from './CTA'
import ME from '../../assets/me.webp'
import HeaderSocials from './HeaderSocials'
import Typewriter from 'typewriter-effect';

function Header() {

  return (
    <header>
      <div className="container header_container">
      
        <h5>Hello I'm</h5>
        <h1>Jamiul Alam Rejon</h1>
        <h2 className='text-light' id='typewriter'><Typewriter
          options={{
            strings: ['Digital Marketing Consultant', 'SEO Expert in Bangladesh', 'Local SEO Expert', 'White-Hat-SEO Expert in Bangladesh'],
            autoStart: true,
            loop: true,
          }}
        /></h2>
        <CTA />
        <a href='#contact' className="scroll_down">Scroll Down</a>
        <HeaderSocials />
        <div className="me">
          <img rel="preload" src={ME} alt="Jamiul Alam Rejon | Digital Marketing and SEO expert in Bangladesh" />
        </div>
      </div>
    </header>
  )
}

export default Header
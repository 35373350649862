import React from "react";
import ReactDOM from "react-dom/client";
import './index.css'
import App from './App';
import Privacy from './pages/Privacy'
import Page404 from './pages/Page404'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { hydrate, render } from "react-dom";


const MainApp = (
    <BrowserRouter>
        <Routes>

            <Route index element={<App />} />
            <Route exact path="privacy-policy" element={<Privacy />} />
            <Route exact path="*" element={<Page404 />} />

        </Routes>
    </BrowserRouter>
);


const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
    hydrate(MainApp, rootElement);
} else {
    render(MainApp, rootElement);
}



// ReactDOM.render (
//     <BrowserRouter>
//         <Routes>

//             <Route  index element={<App />} />
//             <Route  path="privacy-policy" element={<Privacy />} />
//             <Route  path="*" element={<Page404 />} />

//         </Routes>
//     </BrowserRouter>,
//     document.getElementById("root")
// );
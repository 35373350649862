import React from 'react'
import './contact.css'
import { HiOutlineMail } from 'react-icons/hi'
import { RiMessengerLine } from 'react-icons/ri'
import { BsWhatsapp } from 'react-icons/bs'
import { useRef  } from 'react';
import emailjs from 'emailjs-com';


function Contact() {

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs.sendForm('service_75k8x8f', 'template_644j30q', form.current, '9OogF2QEkNmVkKx9K')
      .then((result) => {
        alert("Email sent successfully!");
      }, (error) => {
        alert("Error Try again!");
      });
  };
  

  return (
    <section id='contact'>
      <h5>Get In Touch</h5>
      <h2>Contact Me</h2>

      <div className="container contact_container">
        <div className="contact_options">

          <article className="contact_option">
            <HiOutlineMail className='contact_option-icon' />
            <h4>Email</h4>
            <h5>email@jamiulalamrejon.com</h5>
            <a href='mailto:email@jamiulalamrejon.com' >Send a message</a>
          </article>
          {/* END ITEM */}

          <article className="contact_option">
            <RiMessengerLine className='contact_option-icon' />
            <h4>Messenger</h4>
            <h5>Jamiul Alam Rejon</h5>
            <a href='https://m.me/jamiulalamrejon.official' target='_blank'>Send a message</a>
          </article>
          {/* END ITEM */}

          <article className="contact_option">
            <BsWhatsapp className='contact_option-icon' />
            <h4>+880-1885661175</h4>
            <a href='https://wa.me/message/IOYOY35WMAVAC1' target='_blank'>Send a message</a>
          </article>
          {/* END ITEM */}
        </div>
        <form ref={form} onSubmit={sendEmail} >
          <input type='text' name='name' placeholder='Your Full Name' required />
          <input type='email' name='email' placeholder='Your Email' required />
          <textarea type='message' name="message" rows='7' placeholder='Your Message' required></textarea>
          {/* {(result == true )? <p>Succexss</p> : <p>Faild</p>} */}
          <button type='submit' className='btn btn-primary'>Send Message</button>
        </form>
       
      </div>
    </section>
  )
}

export default Contact
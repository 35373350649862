import React from 'react'
import './portfolio.css'
import IMG1 from '../../assets/jamiulalamrejon_portfolio1.webp'
import IMG2 from '../../assets/jamiulalamrejon_portfolio2.webp'
import IMG3 from '../../assets/jamiulalamrejon_portfolio3.webp'
import IMG4 from '../../assets/jamiulalamrejon_portfolio4.webp'
import IMG5 from '../../assets/jamiulalamrejon_portfolio5.webp'
import IMG6 from '../../assets/jamiulalamrejon_portfolio6.webp'

const data = [
  {
    image: IMG1,
    title: 'SEO Growth',
    alttag: 'Jamiul alam rejon | SEO Growth'
  },
  {
    image: IMG2,
    title: 'Local SEO',
    alttag: 'Jamiul alam rejon | Local SEO'
  },
  {
    image: IMG3,
    title: 'Google Ads',
    alttag: 'Jamiul alam rejon | Google Ads'
  },
  {
    image: IMG4,
    title: 'Facebook Ads',
    alttag: 'Jamiul alam rejon | Facebook Ads'
  },
  {
    image: IMG5,
    title: 'Google Analytics',
    alttag: 'Jamiul alam rejon | Google Analytics'
  },
  {
    image: IMG6,
    title: 'Social Chat Bot',
    alttag: 'Jamiul alam rejon | Social Chat Bot'
  }
]


function Portfolio() {
  return (
    <section id="portfolio">
      <h5>My Recent Work</h5>
      <h2>Portfolio</h2>

      <div className="container portfolio_container">
      {
        data.map(({image, title, alttag}, index) => {
          return (
            <article key={index} className='portfolio_item'>
          <div className="portfolio_item-image">
            <img src={image} alt={alttag}/>
          </div>
            <h3 className=''>{title}</h3>
            {/* <div className="portfolio_item-cta">
               <p>{desc}</p> 
             <a href='#' className='btn btn-primary' target='_blank'>See More</a> 
            </div> */}
        </article>
          )
        })
      }
        {/*END CARD*/}
       
      </div>
    </section>
  )
}

export default Portfolio
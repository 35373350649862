import React from 'react'
import './services.css'
import {IoMdDoneAll} from 'react-icons/io'

function Services() {
  return (
    <section id='services'>
      <h5>What I Offer</h5>
      <h2>Services</h2>

      <div className="container services__container">
        <article className='service'>
          <div className="service__head">
            <h3>
              FACEBOOK
            </h3>
          </div>

          <ul className='service__list'>
            <li>
              <IoMdDoneAll className='service__list-icon'/>
              <p>Proper Guide for New STARTUP.</p>
              </li>
              <li>
              <IoMdDoneAll className='service__list-icon'/>
              <p>Page Competitor Research.</p>
              </li>
              <li></li>
              <li>
              <IoMdDoneAll className='service__list-icon'/>
              <p>Professional Facebook Page Setup.</p>
              </li>
              <li>
              <IoMdDoneAll className='service__list-icon'/>
              <p>Pixel, Catalog & Automated Response Setup.</p>
              </li>
              <li>
              <IoMdDoneAll className='service__list-icon'/>
              <p>Facebook Markeing & Analysis.</p>
              </li>
              <li>
              <IoMdDoneAll className='service__list-icon'/>
              <p>Facebook Boosting/Advertisement</p>
              </li>
              <li>
              <IoMdDoneAll className='service__list-icon'/>
              <p>Reach, Lead, Website, Store & App promotion</p>
              </li>
              <li>
              <IoMdDoneAll className='service__list-icon'/>
              <p>Website Chat Bot Setup.</p>
              </li>
              <li>
              <IoMdDoneAll className='service__list-icon'/>
              <p>Facebook Business Verified(Legal Document).</p>
              </li> 
              <li>
              <IoMdDoneAll className='service__list-icon'/>
              <p>Free consultancy Support.</p>
              </li> 
          </ul>
        </article>
        {/*END OF CARD*/}
        <article className='service'>
          <div className="service__head">
            <h3>
              SEO
            </h3>
          </div>

          <ul className='service__list'>
            <li>
              <IoMdDoneAll className='service__list-icon'/>
              <p>Proper Guide about SEO GROWTH.</p>
              </li>
              <li>
              <IoMdDoneAll className='service__list-icon'/>
              <p>Website SEO Audit.</p>
              </li>
              <li>
              <IoMdDoneAll className='service__list-icon'/>
              <p>Competitor Analysis & Keyword Gap.</p>
              </li>
              <li>
              <IoMdDoneAll className='service__list-icon'/>
              <p>Keyword Research(KGR).</p>
              </li>
              <li>
              <IoMdDoneAll className='service__list-icon'/>
              <p>On Page SEO.</p>
              </li>
              <li>
              <IoMdDoneAll className='service__list-icon'/>
              <p>Technical SEO.</p>
              </li>
              <li>
              <IoMdDoneAll className='service__list-icon'/>
              <p>Local SEO.</p>
              </li>
              <li>
              <IoMdDoneAll className='service__list-icon'/>
              <p>E-commerce SEO.</p>
              </li>
              <li>
              <IoMdDoneAll className='service__list-icon'/>
              <p>Websit SEO Bug Fix.</p>
              </li>
              <li>
              <IoMdDoneAll className='service__list-icon'/>
              <p>Conversation Rate Optimization</p>
              </li>
              <li>
              <IoMdDoneAll className='service__list-icon'/>
              <p>Google Analytics & Tag Manager.</p>
              </li>
              <li>
              <IoMdDoneAll className='service__list-icon'/>
              <p>Free SEO consultancy Support.</p>
              </li>
          </ul>
        </article>
        {/*END OF CARD*/}
        <article className='service'>
          <div className="service__head">
            <h3>
              Advertisement
            </h3>
          </div>

          <ul className='service__list'>
              <li>
              <IoMdDoneAll className='service__list-icon'/>
              <p>PPC Campaign</p>
              </li>
              <li>
              <IoMdDoneAll className='service__list-icon'/>
              <p>All Social Plantform</p>
              </li>
              <li>
              <IoMdDoneAll className='service__list-icon'/>
              <p>Ads Copy </p>
              </li>
              <li>
              <IoMdDoneAll className='service__list-icon'/>
              <p>AB Testing</p>
              </li>
              <li>
              <IoMdDoneAll className='service__list-icon'/>
              <p>Ads Rank</p>
              </li>
              <li>
              <IoMdDoneAll className='service__list-icon'/>
              <p>Ads desing(Image, Animated-Image/Video)</p>
              </li>
              <li>
              <IoMdDoneAll className='service__list-icon'/>
              <p>Targeted Audience Research.</p>
              </li>
              <li>
              <IoMdDoneAll className='service__list-icon'/>
              <p>Budget Optimization(ROI).</p>
              </li>
              <li>
              <IoMdDoneAll className='service__list-icon'/>
              <p>Location Track & Details Targeting.</p>
              </li>
              <li>
              <IoMdDoneAll className='service__list-icon'/>
              <p>Free Consultancy Support.</p>
              </li>
          </ul>
        </article>
      </div>

    </section>
  )
}

export default Services
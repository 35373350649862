import React, { useState } from 'react'
import './nav.css'
import {BiHomeSmile} from 'react-icons/bi'
import {BiUser} from 'react-icons/bi'
import {BiBookOpen} from 'react-icons/bi'
import {RiServiceLine} from 'react-icons/ri'
import {TbMessageDots} from 'react-icons/tb'

function Nav() {
  const [activeNavbar, setActiveNavbar] = useState('#')
  return (
    <nav>
      <a href='#' onClick={() => setActiveNavbar('#')}  className={activeNavbar === '#' ? 'active' : ''}><BiHomeSmile /></a>
      <a href='#about' onClick={() => setActiveNavbar('#about')} className={activeNavbar === '#about' ? 'active' : ''}><BiUser /></a>
      <a href='#experience' onClick={() => setActiveNavbar('#experience')} className={activeNavbar === '#experience' ? 'active' : ''}><BiBookOpen /></a>
      <a href='#services' onClick={() => setActiveNavbar('#services')} className={activeNavbar === '#services' ? 'active' : ''}><RiServiceLine /></a> 
      <a href='#contact' onClick={() => setActiveNavbar('#contact')} className={activeNavbar === '#contact' ? 'active' : ''}><TbMessageDots /></a>
    </nav>
  )
}

export default Nav


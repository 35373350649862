import React from 'react'
import './about.css'
import ME from '../../assets/jamiulalamrejon.webp'
import {FaAward} from 'react-icons/fa'
import {FaUsers} from 'react-icons/fa'
import {AiOutlineFileProtect} from 'react-icons/ai'


function About() {
  return (
    <section id='about'>
      <h5>Get to Know</h5>
      <h2>About Me</h2>

      <div className="container about_container">
        <div className="about__info">
          <div className="about_me_image">
            <img src={ME} alt='Jamiul Alam Rejon | SEO Expert in Bangladesh' />
          </div>
        </div>

        <div className="about_content">
          <div className="about_cards">
            <article className="about_card">
              <FaAward className='card_icon'/>
              <h5>Experience</h5>
              <small>3+ Years Working</small>
            </article>
            <article className="about_card">
              <FaUsers className='card_icon'/>
              <h5>Clients</h5>
              <small>50+ Worldwide</small>
            </article>
            <article className="about_card">
              <AiOutlineFileProtect className='card_icon'/>
              <h5>Projects</h5>
              <small>80+ Completed</small>
            </article>
          </div>

          <p>
          Hello, I'm Jamiul alam rejon, a <strong>professional Digital Marketing Consultant and SEO Expert. </strong>
          I have 3+ years of B2B and B2C digital marketing experience across multiple industries, creating strategies and growth for worldwide clients.
          </p>
          <p>
          I specialize in helping businesses to grow their brand online, increase leads and sales, and generate online business growth. Partner with me and let's grow your business online together!
          </p>
          
          <a href='#contact' className='btn btn-primary'>Get Free Consultancy</a>
        </div>
      </div>
    </section>
  )
}

export default About

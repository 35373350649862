import React from 'react'

function CTA() {
  return (
    <div className='cta'>
        {/* <a href={CV} download className='btn'>Download CV</a> */}
        <a href='/blog' className='btn'>Read Blog</a>
        <a href='#contact' className='btn btn-primary'>Let's Talk</a>
    </div>
  )
}

export default CTA
import React from 'react'
import './footer.css'
import{BsLinkedin} from 'react-icons/bs'
import{BsFacebook} from 'react-icons/bs'
import{BsTwitter} from 'react-icons/bs'
import{BsInstagram} from 'react-icons/bs'
import{BsYoutube} from 'react-icons/bs'
import {Link } from "react-router-dom";

function Footer() {
  return (
    <div>
      <footer>
        <a href="/" className="footer_logo">JAMIUL ALAM REJON</a>
        <ul className="permalink">
          <li><a href='https://jamiulalamrejon.com/#'>Home</a></li>
          <li><a href='https://jamiulalamrejon.com/blog'>Blog</a></li>
          <li><a href='https://jamiulalamrejon.com/#about'>About Me</a></li>
          <li><a href='https://jamiulalamrejon.com/#experience'>Experience</a></li>
          <li><a href='https://jamiulalamrejon.com/#services'>Services</a></li>
          <li><a href='https://jamiulalamrejon.com/#portfolio'>Portfolio</a></li>
          <li><a href='https://jamiulalamrejon.com/#testimonials'>Testimonials</a></li>
          <li><a href='https://jamiulalamrejon.com/#contact'>Contact</a></li>
        </ul>

        <div className="footer_socials">
        <a href='https://www.linkedin.com/in/jamiulalamrejon/' target="_blank"><BsLinkedin /></a>
        <a href='https://web.facebook.com/jamiulalamrejoncom/' target="_blank"><BsFacebook /></a>
        <a href='https://www.instagram.com/jamiulalamrejon/' target="_blank"><BsInstagram /></a>
        <a href='https://twitter.com/Jamiulalamrejon' target="_blank"><BsTwitter /></a>
        <a href='https://www.youtube.com/channel/UC7dK-BbtXNUYZC-nZ__d1UQ' target="_blank"><BsYoutube /></a>
        </div>
        <p className='footer_copywrite'>Copyright © {new Date().getFullYear()} | <Link to="/privacy-policy">Privacy Policy</Link></p>
        
      </footer>
    </div>
  )
}

export default Footer
import React from 'react'
import './testimonials.css'
import AVTR1 from '../../assets/avatar1.jpg'
import AVTR2 from '../../assets/avatar2.jpg'
import AVTR3 from '../../assets/avatar3.jpg'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader  
import { Carousel } from 'react-responsive-carousel'; 


const data = [
  {
    avatar: AVTR3,
    username: 'Alex Jon Doe',
    review: 'It was a pleasure working with you, thank you very much. My website is now getting more visitors'
  },
  {
    avatar: AVTR2,
    username: 'Robert Nicholas',
    review: 'Awesome facebook ad campaign doubled my sales. Professional and Satisfied work.'
  },
  {
    avatar: AVTR1,
    username: 'Nora',
    review: 'Now I get more visitors from search engines than before. Thank You.'
  }
]

function Testimonials() {
  
  return (
    <section id='testimonials'>
      <h5>Review from clients</h5>
      <h2>Testimonials</h2>

      <Carousel className="container testimonials_container" 
      autoPlay='true' 
      infiniteLoop='true' 
      >
       {
        data.map(({avatar, username, review}, index) => {
          return (
            <article key={index} className='testimonial'>
            <div className="client_avatar">
              <img src={avatar} alt='Testimonials'/>
            </div>
            <h5 className='client_name'>{username}</h5>
           <small className='client_review'>{review}</small>
          </article>
          )
        })
       }
      </Carousel>
    </section>
  )
}

export default Testimonials